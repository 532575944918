.App {
    padding: 1em;
    background-color: darkslateblue;
    min-height: calc(100vh - 2em);
}

.App-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Header-language-option {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.Header-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Button-wrapper {
    width: 80vw;
    margin: .5em 0;
}