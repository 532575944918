h1 {
    font-size: 20px;
    color: white
}

.App-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Header-language-option {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.Header-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Button-wrapper {
    width: 85%;
    margin: .5em auto;
}